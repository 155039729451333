import React, { useState, useRef } from "react";
import ChatInput from "./ChatInput";
import ChatResponse from "./ChatResponse";
import InfoBox from "./InfoBox";
import messageBot from "../assets/messageBot.mp3";
import messageUser from "../assets/messageUser.mp3";

export default function Chat() {
    const messageBotNotification = useRef(new Audio(messageBot));
    const messageUserNotification = useRef(new Audio(messageUser));

    const [chat, setChat] = useState([
        {
            type: "bot",
            text: "Hey there! I'm Krystian's virtual assistant. How can I assist you today?",
        },
    ]);

    const [isBotTyping, setIsBotTyping] = useState(false);

    const handleChatSubmit = async (message) => {
        // Play user message notification
        messageUserNotification.current.play();

        // Add user's message to chat
        setChat((prevChat) => [...prevChat, { type: "user", text: message }]);
        setIsBotTyping(true);

        // Prepare conversation history for the API
        const conversationHistory = chat.map((msg) => ({
            role: msg.type === "bot" ? "assistant" : "user",
            content: msg.text,
        }));

        // Add the new user message
        conversationHistory.push({ role: "user", content: message });

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/chat`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    conversation: conversationHistory
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            const botResponse = data.message;

            // Add bot's response to chat
            setChat((prevChat) => [...prevChat, { type: "bot", text: botResponse }]);
            setIsBotTyping(false);

            // Play bot message notification
            messageBotNotification.current.play();
        } catch (error) {
            console.error("Error fetching bot response:", error);
            setIsBotTyping(false);
            setChat((prevChat) => [
                ...prevChat,
                {
                    type: "bot",
                    text: "I'm sorry, but I'm having trouble responding at the moment. Please try again later.",
                },
            ]);
        }
    };

    return (
        <div className="flex flex-col w-full max-w-lg mx-auto bg-white rounded-xl shadow-md overflow-hidden">
            <ChatResponse chat={chat} isBotTyping={isBotTyping} />
            <ChatInput handleChatSubmit={handleChatSubmit} isBotTyping={isBotTyping} />
            <InfoBox />
        </div>
    );
}